import { useTranslation } from 'react-i18next'
import { CommonStepper, Icon } from '@/components/Elements'
import { useAppState } from '@/features/app/hooks'
import { BOOKING_TYPE } from '@/features/bookings/consts/booking'
import { getType } from '@/features/bookings/helpers/booking/type'
import { useUser } from '@/features/user'
import useStyles from './Stepper.styles'

interface IProps {
  step: number
  bookingType: BOOKING_TYPE
}

export const Stepper = ({ step, bookingType }: IProps) => {
  const { t } = useTranslation()
  const { user } = useUser()

  const { classes } = useStyles()
  const {
    appState: { mobileView },
  } = useAppState()

  const iconSize = mobileView ? 20 : 32
  const stepsOcasionalOdd = [
    {
      label: t('book_baby_sister').replace('%BOOKING_TYPE%', getType(bookingType)?.title),
      icon: <Icon name={'map-pin'} size={iconSize} viewPort={32} />,
    },
    {
      label: t('care_details'),
      icon: <Icon name={'care-details'} size={iconSize} viewPort={32} />,
    },
    {
      label: t('payment_method'),
      icon: <Icon name={'credit-card'} size={iconSize} viewPort={32} />,
    },
  ]
  const stepsOcasionalEven = [
    {
      label: t('book_baby_sister').replace('%BOOKING_TYPE%', getType(bookingType)?.title),
      icon: <Icon name={'map-pin'} size={iconSize} viewPort={32} />,
    },
    {
      label: t('payment_method'),
      icon: <Icon name={'credit-card'} size={iconSize} viewPort={32} />,
    },
    {
      label: t('care_details'),
      icon: <Icon name={'care-details'} size={iconSize} viewPort={32} />,
    },
  ]

  const stepsRegular = [
    {
      label: t('book_baby_sister').replace('%BOOKING_TYPE%', getType(bookingType)?.title),
      icon: <Icon name={'map-pin'} size={iconSize} viewPort={32} />,
    },
    {
      label: t('care_details'),
      icon: <Icon name={'care-details'} size={iconSize} viewPort={32} />,
    },
    {
      label: t('booking_submitted'),
      icon: <Icon name={'check'} size={iconSize} viewPort={32} />,
    },
  ]

  return (
    <div className={classes.wrapper}>
      <CommonStepper
        steps={
          bookingType == BOOKING_TYPE.ONETIME || bookingType == BOOKING_TYPE.REGULAR
            ? user.id % 2 == 0
              ? stepsOcasionalEven
              : stepsOcasionalOdd
            : stepsRegular
        }
        active={step}
      />
    </div>
  )
}
