import classNames from 'classnames'
import { Group, Header as MantineHeader } from '@mantine/core'
import { AppLangPicker, LogoIcon } from '@/components/Elements'
import { BOOKING_TYPE } from '@/features/bookings/consts/booking'
import { MobileBackLink } from '@/features/header/layout/BackLink/MobileBackLink'
import { Info } from '@/features/header/layout/Info'
import { Support } from '@/features/header/layout/Support'
import useStyles from './MobileHeader.styles'

interface IProps {
  bookingType: BOOKING_TYPE
  step: number
}

export const MobileHeaderBooking = ({ bookingType, step }: IProps) => {
  const { classes } = useStyles()
  const isCheckout =
    step == 1 && (bookingType == BOOKING_TYPE.ONETIME || bookingType == BOOKING_TYPE.REGULAR)
  const isStartPage = step == 0
  return (
    <MantineHeader
      height={60}
      p="xs"
      styles={(theme) => ({
        root: {
          backgroundColor: 'transparent',
          border: 'none',
          display: 'flex',
        },
      })}
      className={classes.noborder}
    >
      <Group position="apart" className={'w-100 gap-0'}>
        <div className={classes.third}>
          <div className={classes.itemsLeft}>
            {isStartPage && <MobileBackLink goToBookNow={true} />}
            {!isCheckout && (
              <div className={classes.flag}>
                <AppLangPicker />
              </div>
            )}
          </div>
        </div>
        <div className={classNames('text-center', classes.third)}>
          <LogoIcon size={'sm'} />
        </div>
        <div
          className={classNames(classes.third, classes.headerRight, 'gap-1 d-flex align-center')}
        >
          {!isCheckout && <Info size={36} bookingType={bookingType} />}
          <Support size={20} />
        </div>
      </Group>
    </MantineHeader>
  )
}
