import {
  Control,
  Controller,
  FieldPath,
  FieldValues,
  UseFormGetValues,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form'
import { Modal } from '@/components/Elements'
import { TextInput, TextInputProps } from '@/components/FormElements/TextInput'
import { usePopup } from '@/hooks'
import TimeWheelPicker from './TimeWheelPicker'

interface TimeInputControllerProps<T extends FieldValues> extends TextInputProps {
  name: FieldPath<T>
  shouldUnregister?: boolean
  defaultValue?: any
  meta?: any
  control: Control<T, any>
  getValues: UseFormGetValues<T>
  setValue: UseFormSetValue<T>
  watch: UseFormWatch<T>
}

export const TimeController = <T extends FieldValues>({
  control,
  getValues,
  setValue,
  watch,
  name,
  shouldUnregister,
  defaultValue,
  ...rest
}: TimeInputControllerProps<T>) => {
  const { visible, open, close } = usePopup()

  return (
    <>
      <Controller
        name={name}
        control={control}
        shouldUnregister={shouldUnregister}
        render={({ field: { ref, ...field }, fieldState, formState }) => {
          const meta = {
            error: fieldState.error,
          }

          return (
            <TextInput
              readOnly
              {...field}
              fieldRef={ref}
              meta={meta}
              {...rest}
              onClick={open}
              placeholder="__ : __"
            />
          )
        }}
      />
      <Modal size={290} opened={visible} onClose={close} centered>
        <TimeWheelPicker name={name} setValue={setValue} getValues={getValues} close={close} />
      </Modal>
    </>
  )
}
