import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { DataShower } from '@/components/Elements'
import { bookingsData } from '@/features/bookings/store'
import { useAppSelector } from '@/store'

interface IProps {
  id: number | string
  children: React.ReactNode
}

export const BookingDataLoader = ({ id, children }: IProps) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(bookingsData.getData({ id }))

    return () => {
      dispatch(bookingsData.cleanState())
    }
  }, [])

  const { data, loading, error } = useAppSelector((state) => state.bookings.data)

  return (
    <DataShower isLoading={loading} isFetched={!!data} isFailed={!!error} error={error}>
      {children}
    </DataShower>
  )
}
