import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mantine/core'
import { TextInputController } from '@/components/FormElements'
import { useAppState } from '@/features/app/hooks'

export const DiscountFormSection = () => {
  const { t } = useTranslation()

  const { control } = useFormContext()

  const {
    appState: { mobileView },
  } = useAppState()

  return (
    <div className="flex flex-col">
      <Grid className={'mt-0'}>
        <Grid.Col span={mobileView ? 12 : 9} xs={10}>
          <TextInputController
            control={control}
            name={'discount_code'}
            id={'discount_code'}
            size={mobileView ? 'md' : 'lg'}
            label={t('discount_code')}
            placeholder={t('discount_code')}
            mb={'md'}
            styles={
              mobileView
                ? {
                    label: {
                      fontWeight: 'bold',
                      fontSize: '16px !important',
                    },
                  }
                : {}
            }
          />
        </Grid.Col>
      </Grid>
    </div>
  )
}
