import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import * as Yup from 'yup'
import { Anchor, Space } from '@mantine/core'
import { Alert, Button } from '@/components/Elements'
import {
  CheckboxFieldController,
  PasswordInputController,
  TextInputController,
} from '@/components/FormElements'
import { EXTERNAL_LINK } from '@/const/core'
import { useAppState, useEvents } from '@/features/app/hooks'
import { ROLE } from '@/features/user'
import { FormTypes } from '@/types'
import { ValidationsUtils } from '@/utils'
import useStyles from './RegisterForm.styles'

export type FormValues = {
  email: string
  first_name: string
  last_name: string
  password: string
  password_confirmation: string
  referral_code?: string
  accept: boolean
}

interface IProps {
  onSubmit: (values: FormValues) => Promise<void>
  success: boolean
  role?: ROLE
  newUer?: any
}

const passwordMinLength = 8

export const RegisterForm: React.FC<IProps> = (props) => {
  const { t } = useTranslation()

  const [searchParams, setSearchParams] = useSearchParams()
  const { classes } = useStyles()
  const event = useEvents()
  const {
    appState: { mobileView },
  } = useAppState()

  useEffect(() => {
    const share = searchParams.get('share')
    if (share) {
      event.actions.shareClickSocialMedia(share, searchParams.get('ref'))
    }
  }, [])

  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting },
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      email: props.newUer?.email || '',
      first_name: props.newUer?.first_name || '',
      last_name: props.newUer?.last_name || '',
      password: '',
      password_confirmation: '',
      referral_code: searchParams.get('ref') || '',
      accept: false,
    },
    resolver: yupResolver(
      Yup.object().shape({
        email: Yup.string().email('field.error.invalid').required('field.error.required'),
        first_name: Yup.string().required('field.error.required'),
        last_name: Yup.string()
          .nullable()
          .when([], {
            is: () => props.role == ROLE.Babysitter,
            then: Yup.string().required('field.error.required'),
          }),
        password: Yup.string()
          .required('field.error.required')
          .min(passwordMinLength, 'field.error.password.length'),
        password_confirmation: Yup.string()
          .required('field.error.required')
          .oneOf([Yup.ref('password'), null], 'field.error.password.no_match')
          .min(passwordMinLength, 'field.error.password.length'),
        accept: Yup.bool().oneOf([true], 'required'),
      })
    ),
  })

  const [alertError, setAlertError] = useState<string | null>(null)

  const handleGoogleLogin = async () => {
    const role = window.location.pathname.split('/').pop()
    window.location.href = process.env.REACT_APP_BASE_URL + `auth/google/?role=${role}`
  }
  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    setAlertError(null)
    try {
      await props.onSubmit(data)
    } catch (err) {
      const serverError = err as FormTypes.ValidationErrors
      setAlertError(serverError?.message || t('error'))
      ValidationsUtils.setServerSideErrors(serverError?.errors, setError)
    }
  }

  const watchAccept = watch('accept')

  return (
    <div className={classes.wrap}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {alertError && (
          <Alert type={'error'} mb={'sm'}>
            {alertError}
          </Alert>
        )}

        <TextInputController
          control={control}
          name={'first_name'}
          id={'first_name'}
          size={mobileView ? 'md' : 'lg'}
          label={t('first_name')}
          placeholder={t('first_name')}
          mb={'md'}
        />

        {props.role === ROLE.Babysitter && (
          <TextInputController
            control={control}
            name={'last_name'}
            id={'last_name'}
            size={mobileView ? 'md' : 'lg'}
            label={t('last_name')}
            placeholder={t('last_name')}
            mb={'md'}
          />
        )}

        <TextInputController
          control={control}
          name={'email'}
          id={'email'}
          size={mobileView ? 'md' : 'lg'}
          label={t('email')}
          placeholder={t('email')}
          mb={'md'}
        />

        <PasswordInputController
          control={control}
          name={'password'}
          id={'password'}
          size={mobileView ? 'md' : 'lg'}
          label={t('password')}
          placeholder={t('password')}
          mb={'md'}
          translateParams={{ count: passwordMinLength }}
        />

        <PasswordInputController
          control={control}
          name={'password_confirmation'}
          id={'password_confirmation'}
          size={mobileView ? 'md' : 'lg'}
          label={t('confirm_password')}
          placeholder={t('confirm_password')}
          mb={'md'}
          translateParams={{ count: passwordMinLength }}
        />

        {/*props.role === ROLE.Client && (
          <TextInputController
            control={control}
            name={'referral_code'}
            id={'referral_code'}
            size={mobileView ? 'md' : 'lg'}
            label={t('referral_code') + ' (' + t('optional').toLowerCase() + ')'}
            placeholder={t('referral_code')}
            mb={'md'}
          />
        )*/}

        <CheckboxFieldController
          control={control}
          name={'accept'}
          id={'accept'}
          label={
            <>
              {t('register_terms_label')}{' '}
              <Anchor component={'a'} href={EXTERNAL_LINK.TERMS_AND_CONDITIONS} target={'_blank'}>
                {t('register_terms_text')}
              </Anchor>
            </>
          }
          mb={'md'}
        />

        {!props.success && (
          <Button
            mt={'lg'}
            fullWidth
            color={mobileView ? 'primary' : 'secondary'}
            type={'submit'}
            disabled={isSubmitting || !watchAccept}
            loading={isSubmitting}
          >
            {t('create_account')}
          </Button>
        )}
        {mobileView && props.success && (
          <>
            <Space h={'md'} />
            <Alert type={'success'} mb={'sm'}>
              {t('confirmation-email-sent')}!
            </Alert>
          </>
        )}

        <div className="mt-10">
          <div className="relative">
            <div className="absolute inset-0 flex items-center" aria-hidden="true">
              <div className="w-full border-t border-gray-200 horizontal-line" />
            </div>
            <div className="relative flex justify-center text-sm font-medium leading-6">
              <span className="bg-white px-6 text-gray-900">{t('or_continue_with')}</span>
            </div>
          </div>

          {mobileView && (
            <div className="mt-6 flex justify-content-center">
              <a
                onClick={handleGoogleLogin}
                className="flex cursor-pointer no-underline items-center justify-center gap-3 rounded-lg bg-white px-2 py-2 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-50 focus-visible:ring-transparent border border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                <svg className="h-5 w-5" aria-hidden="true" viewBox="0 0 24 24">
                  <path
                    d="M12.0003 4.75C13.7703 4.75 15.3553 5.36002 16.6053 6.54998L20.0303 3.125C17.9502 1.19 15.2353 0 12.0003 0C7.31028 0 3.25527 2.69 1.28027 6.60998L5.27028 9.70498C6.21525 6.86002 8.87028 4.75 12.0003 4.75Z"
                    fill="#EA4335"
                  />
                  <path
                    d="M23.49 12.275C23.49 11.49 23.415 10.73 23.3 10H12V14.51H18.47C18.18 15.99 17.34 17.25 16.08 18.1L19.945 21.1C22.2 19.01 23.49 15.92 23.49 12.275Z"
                    fill="#4285F4"
                  />
                  <path
                    d="M5.26498 14.2949C5.02498 13.5699 4.88501 12.7999 4.88501 11.9999C4.88501 11.1999 5.01998 10.4299 5.26498 9.7049L1.275 6.60986C0.46 8.22986 0 10.0599 0 11.9999C0 13.9399 0.46 15.7699 1.28 17.3899L5.26498 14.2949Z"
                    fill="#FBBC05"
                  />
                  <path
                    d="M12.0004 24.0001C15.2404 24.0001 17.9654 22.935 19.9454 21.095L16.0804 18.095C15.0054 18.82 13.6204 19.245 12.0004 19.245C8.8704 19.245 6.21537 17.135 5.2654 14.29L1.27539 17.385C3.25539 21.31 7.3104 24.0001 12.0004 24.0001Z"
                    fill="#34A853"
                  />
                </svg>
              </a>
            </div>
          )}
          {!mobileView && (
            <div className="mt-6 flex gap-4">
              <a
                onClick={handleGoogleLogin}
                className="flex cursor-pointer no-underline w-full items-center justify-center gap-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-50 focus-visible:ring-transparent border border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                <svg className="h-5 w-5" aria-hidden="true" viewBox="0 0 24 24">
                  <path
                    d="M12.0003 4.75C13.7703 4.75 15.3553 5.36002 16.6053 6.54998L20.0303 3.125C17.9502 1.19 15.2353 0 12.0003 0C7.31028 0 3.25527 2.69 1.28027 6.60998L5.27028 9.70498C6.21525 6.86002 8.87028 4.75 12.0003 4.75Z"
                    fill="#EA4335"
                  />
                  <path
                    d="M23.49 12.275C23.49 11.49 23.415 10.73 23.3 10H12V14.51H18.47C18.18 15.99 17.34 17.25 16.08 18.1L19.945 21.1C22.2 19.01 23.49 15.92 23.49 12.275Z"
                    fill="#4285F4"
                  />
                  <path
                    d="M5.26498 14.2949C5.02498 13.5699 4.88501 12.7999 4.88501 11.9999C4.88501 11.1999 5.01998 10.4299 5.26498 9.7049L1.275 6.60986C0.46 8.22986 0 10.0599 0 11.9999C0 13.9399 0.46 15.7699 1.28 17.3899L5.26498 14.2949Z"
                    fill="#FBBC05"
                  />
                  <path
                    d="M12.0004 24.0001C15.2404 24.0001 17.9654 22.935 19.9454 21.095L16.0804 18.095C15.0054 18.82 13.6204 19.245 12.0004 19.245C8.8704 19.245 6.21537 17.135 5.2654 14.29L1.27539 17.385C3.25539 21.31 7.3104 24.0001 12.0004 24.0001Z"
                    fill="#34A853"
                  />
                </svg>
                <span className="text-sm font-semibold leading-6 no-underline">Google</span>
              </a>
            </div>
          )}
        </div>
      </form>
    </div>
  )
}
