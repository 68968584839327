import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { MegaTagTitle } from '@/components/Elements'
import { useEvents } from '@/features/app/hooks'
import { BookingConfirmedContainer, Layout } from '@/features/booking/components/BookingConfirmed'
import { checkoutState } from '@/features/booking/store/checkout.slice'
import { BOOKING_TYPE } from '@/features/bookings/consts/booking'
import { TAG_MANAGER_EVENT } from '@/features/tag-manager/consts/event'
import { useUser } from '@/features/user'
import { ageMean } from '../../components/Booking/BookingContainer/BookingWizardForm/helpers'

export const Confirmed = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { booking } = useSelector((state: any) => state.booking.checkout)
  const events = useEvents()
  const { user } = useUser()
  const [justCreatedUser, setJustCreatedUser] = useState(false)
  const [paymentConfirmed, setPaymentConfirmed] = useState(false)

  useEffect(() => {
    if (!booking) {
      navigate('/bookings')
    } else {
      setPaymentConfirmed(booking.payment_confirmed)
      const type = booking.type
      const total = (booking.total_amount / 100).toFixed(2)
      const bookingId = booking.id

      if (user.id % 2 == 0) {
        try {
          events.actions.careDetails({
            Type: type,
            Value: total,
            Booking: bookingId,
            AgeMean: ageMean(booking.booking_children),
          })
        } catch (e) {
          console.error('Error sending careDetails event')
          console.error(e)
        }
      } else {
        //if (booking?.payment_confirmed) {
        const purchaseType =
          type === BOOKING_TYPE.ONETIME
            ? TAG_MANAGER_EVENT.PURCHASE_OCASIONAL
            : TAG_MANAGER_EVENT.PURCHASE_REGULAR

        const userHasntBookings = user && !user.has_bookings
        try {
          events.actions.purchaseType(
            purchaseType,
            total,
            bookingId,
            userHasntBookings,
            'care_details_first'
          )
        } catch (e) {
          console.error('Error sending careDetails event')
          console.error(e)
        }
        try {
          events.actions.purchaseEcommerce(total, {
            transaction_id: bookingId,
            coupon: purchaseType,
            affiliation: purchaseType,
            total: total,
            value: total,
            currency: 'EUR',
            items: [
              {
                item_name: purchaseType,
              },
            ],
          })
        } catch (e) {
          console.error('Error sending careDetails event')
          console.error(e)
        }
        //}
      }
      setJustCreatedUser(booking.just_created_user)
    }
    dispatch(checkoutState.setBooking(null))
  }, [])

  return (
    <>
      <MegaTagTitle title={'booking_confirmed'} />

      <Layout>
        <BookingConfirmedContainer
          justCreatedUser={justCreatedUser}
          paymentConfirmed={paymentConfirmed}
        />
      </Layout>
    </>
  )
}
