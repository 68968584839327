import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { UnstyledButton } from '@mantine/core'
import { AvatarProvider } from '@/components/Elements'
import { useAppState } from '@/features/app/hooks'
import { BOOKING_STATUS, BOOKING_TYPE } from '@/features/bookings/consts/booking'
import { ChatModel } from '@/features/chat/types/models'
import { ROLE, useUser } from '@/features/user'
import { useUsersModel } from '@/features/users/hooks'
import { Body } from './Body'
import useStyles from './Item.styles'

interface IProps {
  data: ChatModel
  onSelect: (data: ChatModel) => void
  active: boolean
}

export const Item = ({ data, active, onSelect }: IProps) => {
  const {
    appState: { mobileView },
  } = useAppState()
  const [lastDate, setLastDate] = useState(moment(data.booking?.date_end))

  const { classes, cx } = useStyles()

  const { participants } = data

  const { getAllParticipantsRole } = useUsersModel()
  const { isClient, isBabysitter } = useUser()

  useEffect(() => {
    let lastCalcDate = moment(data.booking?.date_end)
    if (
      (data.booking?.type === BOOKING_TYPE.ONETIME ||
        data.booking?.type === BOOKING_TYPE.ANIMATION) &&
      data.booking?.timetable?.onetime_ranges
    ) {
      data.booking?.timetable?.onetime_ranges.forEach(function (range: any) {
        if (lastCalcDate.isBefore(moment(range.range_date_end))) {
          lastCalcDate = moment(range.range_date_end)
        }
      })
    }
    setLastDate(lastCalcDate)
  }, [data])

  const showRatingRequest = useMemo(() => {
    return (
      data.booking &&
      data.booking.status == BOOKING_STATUS.FINISHED &&
      moment().add(-7, 'days').isBefore(lastDate) &&
      ((isBabysitter() && !data.booking.client_rating) ||
        (!isBabysitter() && !data.booking.rating_service))
    )
  }, [data, lastDate])

  const showKidsImage = useMemo(() => {
    const [hour, minute] = data.booking?.time_start?.split(':') ?? [0, 0]
    return (
      isBabysitter() &&
      data.booking &&
      moment(data.booking.date_start).hour(hour).minute(minute).isBefore(moment()) &&
      moment().add(-5, 'days').isBefore(lastDate) &&
      !data.kids_image_sent
    )
  }, [data, lastDate])

  const display = useMemo(() => {
    return (
      data.last_message ||
      showRatingRequest ||
      showKidsImage ||
      data?.booking.status !== BOOKING_STATUS.FINISHED
    )
  }, [data.booking, data.last_message, lastDate, showRatingRequest])

  const onClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()

    if (active) {
      return
    }

    onSelect(data)
  }

  return (
    <>
      {display && (
        <UnstyledButton
          className={cx(
            classes.root,
            isClient() || getAllParticipantsRole(participants, ROLE.Babysitter).length == 1
              ? classes.rootClient
              : classes.rootBabysitter,
            {
              [`${classes.rootActive}`]: active,
            }
          )}
          onClick={onClick}
        >
          <div className={classes.avatar}>
            <AvatarProvider
              size={mobileView ? 42 : 48}
              multiUser={participants && participants.length > 2}
              avatar={
                isClient() && participants && participants.length == 2
                  ? participants[0].avatar
                  : null
              }
            />
          </div>

          <div className={classes.body}>
            <Body data={data} showRatingRequest={showRatingRequest} />
          </div>
        </UnstyledButton>
      )}
    </>
  )
}
