import { useCallback, useEffect, useRef, useState } from 'react'
import { FieldPath, FieldValues } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Picker, { PickerValue } from 'react-mobile-picker'
import { TextInputProps } from '@mantine/core'
import { Button } from '@/components/Elements'

interface WheelPickerProps<T extends FieldValues> extends TextInputProps {
  name: FieldPath<T>
  setValue?: any
  getValues?: any
  close: () => void
}

export const TimeWheelPicker = <T extends FieldValues>({
  name,
  getValues,
  setValue,
  close,
}: WheelPickerProps<T>) => {
  const { t } = useTranslation()
  const hours = Array.from({ length: 24 }, (_, i) => String(i).padStart(2, '0'))
  const minutes = Array.from({ length: 60 }, (_, i) => String(i).padStart(2, '0'))

  const [focusedColumn, setFocusedColumn] = useState<string>('hour')
  const [timeValue, setTimeValue] = useState<PickerValue>({
    hour: String(getValues(name) == null ? 0 : getValues(name).split(':')[0]).padStart(2, '0'),
    minute: String(getValues(name) == null ? 0 : getValues(name).split(':')[1]).padStart(2, '0'),
  })

  const handleTimeChange = useCallback((newValue: PickerValue, key: string) => {
    setTimeValue(newValue)
  }, [])

  const onOk = () => {
    setValue(name, timeValue.hour + ':' + timeValue.minute)
    close()
  }

  const pickerRef = useRef<any>(null)

  const handleKeyDown = (event: any) => {
    const options = focusedColumn == 'hour' ? hours : minutes
    const currentValue = focusedColumn == 'hour' ? timeValue.hour : timeValue.minute
    const currentIndex = options.indexOf(currentValue + '')
    let newIndex = 0
    if (event.key === 'ArrowUp') {
      newIndex = currentIndex > 0 ? currentIndex - 1 : currentIndex
    } else if (event.key === 'ArrowDown') {
      newIndex = currentIndex + 1 >= options.length ? currentIndex : currentIndex + 1
    } else if (event.key === 'ArrowLeft') {
      setFocusedColumn('hour')
    } else if (event.key === 'ArrowRight') {
      setFocusedColumn('minute')
    }
    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      if (focusedColumn == 'hour') {
        setTimeValue({
          hour: options[newIndex],
          minute: timeValue.minute,
        })
      } else {
        setTimeValue({
          hour: timeValue.hour,
          minute: options[newIndex],
        })
      }
    }
  }

  useEffect(() => {
    // Focus the picker and attach the keyboard event listener
    pickerRef.current?.focus()
    window.addEventListener('keydown', handleKeyDown)

    return () => {
      // Clean up event listener
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [timeValue, focusedColumn])

  return (
    <>
      <div
        className="mt-2"
        tabIndex={0} // Make the div focusable to capture keyboard events
        ref={pickerRef}
      >
        <Picker value={timeValue} onChange={handleTimeChange} wheelMode="natural">
          <Picker.Column name="hour" onClick={() => setFocusedColumn('hour')}>
            {hours.map((hour) => (
              <Picker.Item key={hour} value={hour}>
                {({ selected }) => (
                  <div className={selected ? 'font-semibold text-neutral-900' : 'text-neutral-400'}>
                    {hour}
                  </div>
                )}
              </Picker.Item>
            ))}
          </Picker.Column>
          <div
            style={{
              marginTop: '88px',
              fontSize: '24px',
            }}
          >
            :
          </div>
          <Picker.Column name="minute" onClick={() => setFocusedColumn('minute')}>
            {minutes.map((month) => (
              <Picker.Item key={month} value={month}>
                {({ selected }) => (
                  <div className={selected ? 'font-semibold text-neutral-900' : 'text-neutral-400'}>
                    {month}
                  </div>
                )}
              </Picker.Item>
            ))}
          </Picker.Column>
        </Picker>
      </div>
      <div className="mt-4 gap-2 d-flex justify-end">
        <Button color={'primary'} onClick={onOk}>
          {t('save')}
        </Button>
      </div>
    </>
  )
}

export default TimeWheelPicker
