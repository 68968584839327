import { BOOKING_CANDIDATE_STATUS } from '@/features/booking-candidate/consts/candidate'
import { SPECIFIC_SERVICE_ALIAS } from '@/features/booking-service/consts/specific-services'
import { BOOKING_STATUS } from '@/features/bookings/consts/booking'

export const getSpecificFetchParams = (alias: SPECIFIC_SERVICE_ALIAS) => {
  let params: any = {}

  const includes = [
    'user',
    'primaryLanguage',
    'secondaryLanguage',
    'bookingChildren',
    'myBookingCandidate',
    'babysitters',
  ]

  if (alias === SPECIFIC_SERVICE_ALIAS.POTENTIAL) {
    params = {
      includes,
      per_page: 50,
      filters: {
        candidate_statuses: [BOOKING_CANDIDATE_STATUS.HIRED],
      },
      sort: [{ id: 'date_start', desc: false }],
    }
  } else if (alias === SPECIFIC_SERVICE_ALIAS.UPCOMING) {
    params = {
      includes,
      filters: {
        statuses: [BOOKING_STATUS.UPCOMING, BOOKING_STATUS.REQUEST_FINISH],
      },
      sort: [{ id: 'date_start', desc: false }],
    }
  } else if (alias === SPECIFIC_SERVICE_ALIAS.HISTORY) {
    params = {
      includes: [...includes, 'clientRating'],
      filters: {
        statuses: [
          BOOKING_STATUS.CANCELED_ADMIN,
          BOOKING_STATUS.CANCELED_CLIENT,
          BOOKING_STATUS.CANCELED_BABYSITTER,
          BOOKING_STATUS.FINISHED,
          BOOKING_STATUS.CANCELED_LATE_PAYMENT,
        ],
      },
      sort: [{ id: 'date_start', desc: true }],
    }
  }

  return params
}
