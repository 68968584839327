import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { BookingDataLoader } from '@/features/bookings/components/Booking/BookingDetails'
import { BookingLayout } from './BookingLayout'

interface IProps {
  id: string | number
  onDestroy: () => void
  onDrawerClose: () => void
  ratingProps?: any
}

export const BookingDetailsContainer = ({ id, onDestroy, onDrawerClose, ratingProps }: IProps) => {
  const { t } = useTranslation()

  useEffect(() => {
    return () => onDestroy()
  }, [])

  return (
    <>
      <BookingDataLoader id={id}>
        <BookingLayout onDrawerClose={onDrawerClose} ratingProps={ratingProps} />
      </BookingDataLoader>
    </>
  )
}
