import { debounce } from 'lodash'
import moment from 'moment/moment'
import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mantine/core'
import { DatePickerController } from '@/components/FormElements'
import { TimeController } from '@/components/FormElements/Controllers/TimeInputSelectController/TimeController'
import { useAppState } from '@/features/app/hooks'
import { BOOKING_TYPE } from '@/features/bookings/consts/booking'
import { formatTime, timeToDate } from '@/utils/date'

export const BookingPeriodFormSection = () => {
  const { t } = useTranslation()
  const {
    appState: { mobileView },
  } = useAppState()
  const { control, getValues, setValue, watch, trigger } = useFormContext()

  const watchType = watch('type')

  const watchDateStart = watch('date_start')
  const watchDateEnd = watch('date_end')
  const watchTimeStart = watch('timetable.time_start')

  const calcEndDate = () => {
    const timeStart = timeToDate(getValues('timetable.time_start'))
    const timeEnd = timeToDate(getValues('timetable.time_end'))
    const type = getValues('type')
    if (
      timeStart &&
      type == BOOKING_TYPE.ONETIME &&
      (!timeEnd || !calcTimeDiffOk(timeStart, timeEnd))
    ) {
      const date = moment(timeStart).add(3, 'hours').toDate()
      setValue('timetable.time_end', formatTime(date))
    }
  }

  const debouncedCalcEndDate = React.useCallback(debounce(calcEndDate, 1000), [])

  useEffect(() => {
    if (watchDateStart) {
      trigger('date_start')
    }
    if (watchDateEnd) {
      trigger('date_end')
    }
  }, [watchDateStart, watchDateEnd])

  useEffect(() => {
    if (watchTimeStart) {
      debouncedCalcEndDate()
    }
  }, [watchTimeStart])

  const calcTimeDiffOk = (startTime: any, endTime: any) => {
    const diff = moment()
      .hour(moment(endTime).hour())
      .diff(moment().hour(moment(startTime).hour()))

    const duration = moment.duration(diff)
    return duration.asHours() >= 4 || (duration.asHours() < 0 && duration.asHours() >= -20)
  }

  return (
    <>
      <Grid>
        <Grid.Col span={mobileView ? 6 : 4}>
          <DatePickerController
            control={control}
            name={'date_start'}
            id={'date_start'}
            label={t(watchType == BOOKING_TYPE.ONETIME ? 'service_date' : 'start_date')}
            size={mobileView ? 'md' : 'lg'}
            placeholder={t('start_date')}
            withinPortal
            clearable={false}
            mb={'sm'}
            translateParams={{ date: t('today') }}
            minDate={new Date()}
            styles={
              mobileView
                ? {
                    label: {
                      fontWeight: 'bold',
                      fontSize: '16px !important',
                    },
                  }
                : {}
            }
          />
        </Grid.Col>

        {watchType === BOOKING_TYPE.ANIMATION && (
          <Grid.Col span={mobileView ? 6 : 4}>
            <TimeController
              control={control}
              name={'timetable.time_start'}
              id={'timetable.time_start'}
              label={t('from')}
              size={mobileView ? 'md' : 'lg'}
              mb={'md'}
              setValue={setValue}
              getValues={getValues}
              watch={watch}
              styles={
                mobileView
                  ? {
                      label: {
                        fontWeight: 'bold',
                        fontSize: '16px !important',
                      },
                    }
                  : {}
              }
            />
          </Grid.Col>
        )}
      </Grid>

      <Grid>
        <Grid.Col span={mobileView ? 6 : 4}>
          {watchType === BOOKING_TYPE.ONETIME ? (
            <TimeController
              control={control}
              name={'timetable.time_start'}
              id={'timetable.time_start'}
              label={t('booking.start_hour')}
              size={mobileView ? 'md' : 'lg'}
              mb={'md'}
              setValue={setValue}
              getValues={getValues}
              watch={watch}
              styles={
                mobileView
                  ? {
                      label: {
                        fontWeight: 'bold',
                        fontSize: '16px !important',
                      },
                    }
                  : {}
              }
            />
          ) : (
            <DatePickerController
              control={control}
              name={'date_end'}
              id={'date_end'}
              size={mobileView ? 'md' : 'lg'}
              label={t(
                watchType === BOOKING_TYPE.PERMANENT_NANNY ? 'expected_end_date' : 'end_date'
              )}
              placeholder={t('end_date')}
              mb={'md'}
              withinPortal
              clearable={false}
              translateParams={{ date: t('start_date') }}
              initialMonth={watchDateEnd || watchDateStart}
              minDate={new Date()}
              styles={
                mobileView
                  ? {
                      label: {
                        fontWeight: 'bold',
                        fontSize: '16px !important',
                      },
                    }
                  : {}
              }
            />
          )}
        </Grid.Col>

        {(watchType === BOOKING_TYPE.ONETIME || watchType === BOOKING_TYPE.ANIMATION) && (
          <Grid.Col span={mobileView ? 6 : 4}>
            <TimeController
              control={control}
              name={'timetable.time_end'}
              id={'timetable.time_end'}
              label={t(watchType === BOOKING_TYPE.ONETIME ? 'booking.end_hour' : 'to')}
              size={mobileView ? 'md' : 'lg'}
              mb={'md'}
              setValue={setValue}
              getValues={getValues}
              watch={watch}
              className={mobileView ? 'ml-2' : ''}
              styles={
                mobileView
                  ? {
                      label: {
                        fontWeight: 'bold',
                        fontSize: '16px !important',
                      },
                    }
                  : {}
              }
            />
          </Grid.Col>
        )}
      </Grid>
    </>
  )
}
